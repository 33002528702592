.footer {
  background-color: white;
  padding: 2rem;
  width: 100%;
  min-height: 40vh;
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  /* transform: translateX(-20%); */
  gap: 2rem;
}

.wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10%;
  width: 80%;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  gap: 3rem;
}

.h1_3 {
  color: black;
}

.col {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  color: #000;
}

.col a {
  line-height: 30px;
  color: black;
}

.btn {
  background-color: #161613;
  border-radius: 10rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.1rem 2.1rem;
  text-decoration: none;
  transition: box-shadow 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
}

.btn:hover {
  transform: scale(1.07);
  box-shadow: 0 25px 35px -15px rgba(22, 22, 19, 0.5);
}

.row {
  margin: 0 10%;
  width: 80%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.row span {
  color: black;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: inline-block;
}

.logo {
  font-size: 2rem;
}

.row div {
  display: flex;
  gap: 1rem;
}

.links {
  color: black;
}

.translate {
  transform: translateX(0%);
}

@media screen and (max-width: 820px) {
  .row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .row span {
    font-size: 0.8rem;
  }

  .translate {
    transform: translateX(0%);
  }

  .wrap {
    justify-content: flex-start;
  }
}