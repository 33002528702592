.wrapper {
  border-radius: 20px;
  overflow: hidden;
  padding: 0.5rem 0;
  padding-bottom: 2rem;
}

.location_info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer;
}

.staticMap {
  width: var(--main-width-event-details);
  height: var(--main-height-event-details);
  border-radius: 25px;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}