.all {
  overflow-y: auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(38px) brightness(60%);
  z-index: -1;
}
.wrapper {
  display: flex;
  margin: 0 2.5%;
  width: 95vw;
  margin-top: 1rem;
  gap: 2rem;
}
/* .left {
} */
.right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.right div {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  color: lightgray;
  font-size: 15px;
}
.right div span {
  text-transform: capitalize;
  line-height: 30px;
}
.white {
  color: white;
}

.image {
  object-fit: cover;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .image {
    /* We limit the width to 90% of the screen width */
    width: 90vw;
    /* And keep the aspect ratio */
    height: calc(90vw * (320 / 580));
  }
}
@media screen and (max-width: 1000px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 5%;
    width: 90vw;
    margin-top: 2rem;
    justify-content: center;
    gap: 0rem;
  }
}
