/* Dialog.module.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(77, 77, 77, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    color: white;
    /* Ensure it's above other content */
}

.dialog {
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    /* Adjust based on your needs */
    z-index: 1001;
    /* Above the overlay */
    margin: 2rem;
    /* max-width: 90%; responsive width */
    background-color: #1b212d;
    transition: opacity 0.5s ease-out;
}

.title div {
    background-image: linear-gradient(to right, #a8284a, #da784b);
    padding: 12px;
    line-height: 8px;
    border-radius: 50%;
    cursor: pointer;

}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details hr {
    opacity: 0.5;
    margin: 0 5%;
}

.disabledBtn {
    background-color: rgb(66, 63, 63) !important;
    border-color: rgb(66, 63, 63) !important;
    background-image: none !important;
    cursor: not-allowed !important;
    box-shadow: 0 3px 20px 0 rgb(66, 63, 63) !important;
}

.note {
    margin: 10px 5%;
    line-height: normal;
    background-color: #191c24;
    padding: 5px;
    border-radius: 10px;
}

.details span {
    opacity: 0.7;
}

.details button {
    background-image: linear-gradient(to right, #a8284a, #da784b);
    width: 100%;
    border-radius: 20px;
}

@media screen and (max-width:600px) {
    .title h2 {
        font-size: 16px;
    }

    .title div {
        font-size: 16px;
        font-weight: 500;
    }
}