.normal_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  /* object-fit: cover; */
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.full_img {
  width: var(--main-width-event-details);
  cursor: pointer;
  border-radius: 25px;
  object-fit: cover;
  height: 500px;
  transition: all 0.8s ease-in-out;
}

.img_wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  /* 9 / 16 * 100% = 56.25% */
  overflow: hidden;
  margin-bottom: 15px;
}