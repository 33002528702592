.homepage {
  margin: 20px 10%;
  width: 80vw;
  display: flex;
  flex-direction: column;
}

.event {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1rem;
}

.date {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 30px;
  position: sticky;
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  top: 0;
  padding: 0 1rem;
  z-index: 3;
  width: fit-content;
  /* background-image: url(/assets/bg1.jpg); */
}

/* .scrolled { */
/* background-image: var(--btn-gradient); */
/* backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  padding: 0 1rem; */
/* } */
.header h1 {
  display: flex;
  gap: 5px;
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 10%;
}

.dropdown {
  color: rgb(255, 255, 255) !important;
}

.headspan {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  /* margin-top: 10px; */
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 1000px) {
  .header h1 {
    font-size: 2rem;
    height: 54px;
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .homepage {
    margin: 20px 5%;
    width: 90vw;
  }

  .header h1 {
    font-size: 1.8rem;
    display: flex;
  }

  .header {
    gap: 1rem;
    margin: 0 4%;
  }
}

@media screen and (max-width: 400px) {
  .header h1 {
    font-size: 1.4rem;
    height: 50px;
    display: flex;
  }
}

.appReturn {
  display: none;
}

@media screen and (max-width:650px) {
  .appReturn {
    display: flex;
    justify-content: space-between;
    padding: 10px 2.5%;
    background-color: #1b212d;
    color: white;
  }

  .appReturn a {
    background-image: var(--btn-gradient);
    color: white;
    line-height: normal;
    border-radius: 15px;
    font-size: 12px;
    padding: 5px 10px;
    height: fit-content;
    margin: auto 0;
  }
}