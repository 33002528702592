.eventCard {
  flex: 0 0 calc(33.33% - 2rem);
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.imageContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.eventImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.eventCard a {
  text-decoration: none;
  color: black;
}

.name {
  margin-top: 0.5rem;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: white !important;
  padding-top: 10px;
  max-width: 100%;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 12;
  /* display: -webkit-box; */
}

.event_info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 6px;
}

.event_info span {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.cursor {
  cursor: wait !important;
}

@media screen and (max-width: 1120px) {
  .eventCard {
    flex: 0 0 calc(50% - 2rem);
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 680px) {
  .eventCard {
    flex: 0 0 calc(100%);
    display: flex;
    flex-direction: column;
  }
}