.skeleton_img_wrapper {
  width: var(--main-width-event-details);
  height: var(--main-height-event-details);
  border-radius: 25px;
  padding-bottom: 1rem;
}

.normal_img_skeleton {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: #ddd;
}
